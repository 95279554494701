<template>
  <div>
    <div class="background">
      <img v-if="enterpriseObj" class="logo" :src="enterpriseObj.config.loginBackground" style="width: 100%;height: 100%;"
        alt="" />
      <img v-else src="../../assets/login/bg-login.png" style="width: 100%;height: 100%;" alt="" />
    </div>
    <div class="container">
      <div class="modify">
        <div class="modify-content1" v-loading="modifyLoading">
          <div class="content-login-header">
            <img src="../../assets/login/xian-left.png">
            <h3>完善信息</h3>
            <img src="../../assets/login/xian-right.png">
          </div>
          <div class="pointOut">
            <span><span class="waring"> ！</span>注意，上传身份证并非是获取您的个人信息，仅做信息便捷填写，您也可以自行填写以下内容。</span>
          </div>
          <div style="display: flex;justify-content: space-between;">
            <el-upload class="avatar-uploader touimg" action="/api/app/app-user-identity/ocr" :auto-upload="true"
              :headers="headers" :data="newData" :show-file-list="false" :on-change="changePictureUploadIDFront">
              <img v-if="imageUrlFront" :src="imageUrlFront" class="avatar" style="width: 365px;height: 218px;">
              <div v-else style="display: flex;flex-direction: column">
                <i class="el-icon-plus avatar-uploader-icon" style="font-size: 20px;color: #1371E7;" />
                <div class="zheng">上传正面照</div>
              </div>
            </el-upload>
            <!--              </el-col>-->
            <!--              <el-col :lg="6">-->
            <el-upload class="avatar-uploader touimg2" action="/api/app/app-user-identity/ocr" :auto-upload="true"
              :headers="headers" :data="newData2" :show-file-list="false" :on-change="changePictureUploadIDFront2">
              <img v-if="imageUrlFront2" :src="imageUrlFront2" class="avatar" style="width: 365px;height: 218px;">
              <div v-else style="display: flex;flex-direction: column">
                <i class="el-icon-plus avatar-uploader-icon" style="font-size: 20px;color: #1371E7;" />
                <div class="zheng">上传反面照</div>
              </div>
            </el-upload>
          </div>
          <div class="form-data">
            <el-form :rules="rules" label-width="0px" size="small" ref="messageForm" :model="messageForm"
              label-position="left" :inline="true">
              <el-form-item prop="name"  class="items">
                <div class="label"><span>*</span> 姓名：</div>
                <el-input v-model="messageForm.name" style="width: 300px;"></el-input>
              </el-form-item>
              <el-form-item prop="sex" class="items">
                <div class="label"><span>*</span> 性别：</div>
                <el-input v-model="messageForm.sex" style="width: 300px;"></el-input>
              </el-form-item>
              <el-form-item prop="idNumber" class="items">
                <div class="label"><span>*</span> 身份证：</div>
                <el-input v-model="messageForm.idNumber" style="width: 300px;"></el-input>
              </el-form-item>
              <el-form-item prop="ethnicity" class="items">
                <div class="label"><span>*</span> 名族：</div>
                <el-input v-model="messageForm.ethnicity" style="width: 300px;"></el-input>
              </el-form-item>
              <el-form-item prop="address" class="items">
                <div class="label"><span>*</span> 住址：</div>
                <el-input v-model="messageForm.address" style="width: 300px;"></el-input>
              </el-form-item>
              <el-form-item class="items">
                <div class="label">出生日期：</div>
                <el-input v-model="messageForm.birthDate" style="width: 300px;"></el-input>
              </el-form-item>
              <el-form-item class="items">
                <div class="label">签发机关：</div>
                <el-input v-model="messageForm.issueAuthority" style="width: 300px;"></el-input>
              </el-form-item>
              <el-form-item class="items">
                <div class="label">有效期限：</div>
                <el-input v-model="messageForm.validPeriod" style="width: 300px;"></el-input>
              </el-form-item>
              <el-form-item style="text-align: center;">
                <el-button style="width: 100px;height:40px;margin-left: 20px;margin-top: 20px;" type="primary"
                  @click="getRenTu('messageForm')">下一步 </el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { httpGet } from "@/utils/httpRequest";
import topNav from "@/components/nav/nav.vue"

var interval;
export default {
  name: "authentication",
  data() {
    return {
      pageIndex: 3,
      navList: ["首页", "选课中心", "帮助中心", "个人中心", "联系我们"],
      active: 0,
      translateX: 700,
      lineWidth: 32,
      imgUrl: ["../assets/be-首屏.png", "../assets/be-首屏.png"],
      // 浮动框
      xPos: document.body.clientWidth - 500,
      yPos: 100,
      xin: true,
      yin: true,
      step: 1,
      delay: 20,
      height: 0,
      heightSet: 0,
      widthSet: 0,
      yon: 0,
      xon: 0,
      pause: true,
      promptShow: true,
      // navStatus:false,
      //  -------
      steps: 1,
      headers: { token: localStorage.getItem("oatoken") },
      newData: {
        name: '',
        idNumber: '',
        side: 1,
        userId: localStorage.getItem('userId'),
      },
      newData2: {
        name: '',
        idNumber: '',
        side: 2,
        userId: localStorage.getItem('userId'),
      },
      countdown: '6',
      timer: null,
      imageUrlFront: '',
      imageUrlFront2: '',
      userId: '',
      userName: '',
      userGender: '',
      file: '',
      deleteFlag: false,
      enterpriseObj: {},
      messageForm: {
        name: '',
        idNumber: '',
        gender: '',
        sex: '',
        ethnicity: '',
        address: '',
        birthDate: '',
        validPeriod: '',
        issueAuthority: '',
      },
      modifyLoading: false,
      rules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
        ],
        idNumber: [
          { required: true, message: '请输入身份证', trigger: 'blur' },
          { min: 15, max: 18, message: '身份证长度异常', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '请输入身份证有效日期', trigger: 'blur' },
        ],
        birthDate: [
          { required: true, message: '请输入出生年月', trigger: 'blur' },
        ],
        ethnicity: [
          { required: true, message: '请输入名族', trigger: 'blur' },
        ],
        sex: [
          { required: true, message: '请输入性别', trigger: 'blur' },
        ],
        validPeriod: [
          { required: true, message: '请输入身份证有效期限', trigger: 'blur' },
        ],
        issueAuthority: [
          { required: true, message: '请输入身份证签发机关', trigger: 'blur' },
        ],
      }
    };
  },
  components: {
    topNav: topNav
  },
  created() {
    this.enterpriseObj = this.$store.getters.enterpriseObj;
  },
  watch: {
    steps: {
      handler(newName, oldName) {
        // console.log('obj.lable changed' + this.steps);
        if (this.steps == 3) {
          this.sendCode()
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    this.userId = localStorage.getItem('userId')
    this.userName = localStorage.getItem('userName')
    this.userGender = localStorage.getItem("userGender")
  },
  methods: {
    //鼠标移入效果
    clearFdAd() {
      clearInterval(interval);
      document.getElementsByClassName('prompt-img')[0].style.display = 'none';
      document.getElementsByClassName('prompt-hover')[0].style.display = 'block';
    },
    //鼠标移出效果
    starFdAd() {
      interval = setInterval(this.changePos, this.delay);
      document.getElementsByClassName('prompt-img')[0].style.display = 'block';
      document.getElementsByClassName('prompt-hover')[0].style.display = 'none';
    },
    // /**
    //  * 点击切换标题
    //  * @param {number} index 菜单序列(点击的第index个)
    //  */
    // changeTitle(index) {
    //   this.active = index;

    //   console.log('===========', index)
    //   this.setUnderLine();

    //   if (index === 4) {
    //     this.$router.push = "./videoplayback/videocatalog.vue";
    //   }
    // },
    /**
     * 设置下划线
     */
    // setUnderLine() {
    //   // 获取屏幕宽
    //   // let width = document.documentElement.clientWidth;

    //   let width = 600;
    //   // 获取菜单宽度
    //   let navWidth = this.$refs.navList.clientWidth;
    //   // console.log(navWidth)
    //   // 每个菜单所占宽度 = 屏宽 / 菜单个数
    //   let slidingWidth = (navWidth / 5).toFixed(2);
    //   // 半个菜单宽度 （为了将下划线与菜单对齐）
    //   let halfSlidingWidth = (slidingWidth / 2).toFixed(2);
    //   // 水平位移目的坐标 = 半个菜单宽度 + (菜单宽 x 当前所在的index)
    //   this.translateX =
    //       width / 2 -
    //       navWidth / 2 +
    //       Number(halfSlidingWidth) +
    //       Number(slidingWidth * this.active);
    //   // console.log(this.translateX)
    //   let arr = [];
    //   for (let i in this.$refs.navItem) {
    //     arr.push(this.$refs.navItem[i].clientWidth);
    //   }
    //   this.lineWidth = arr[this.active];
    // },
    //  -----------定时器-------------
    //倒计时
    // if(this.steps == 3){
    //   this.sendCode();  //触发倒计时
    // },
    sendCode() {
      this.loading();  //启动定时器
      this.timer = setInterval(() => {
        //创建定时器
        if (this.countdown === 1) {
          this.clearTimer();  //关闭定时器
          // this.skipStep();
        } else {
          this.loading();
        }
      }, 1000);
    },
    loading() {
      //启动定时器
      this.countdown--;  //定时器减1
    },
    clearTimer() {
      //清除定时器
      clearInterval(this.timer);
      this.timer = null;
    },
    getRen() {
      if (this.newData.name != '' && this.newData.idNumber != '') {
        this.steps = 2
      } else {
        this.$message.error('请填写姓名和身份证号!')
      }
    },
    getRenTu(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$router.push({
            name: "Enrollren",
            params: {
              // 支付方式 0 微信 1 支付宝 2网银支付
              messageForm: this.messageForm
            }
          })
          console.log(this.messageForm)
        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    },
    getYes() {
      this.deleteFlag = false
      this.$router.push('/enrollren')
    },

    // 文件发生变化时
    changePictureUploadIDFront(file, fileList) {
      if (this.modifyLoading) {
        this.$message({
          message: '请检查个人 信息是否正确',
          type: 'warning'
        });
        // 关闭定时器
        this.modifyLoading = false;
      } else {
        // 打开定时器
        this.modifyLoading = true;
      }
      if (file.response) {
        // console.log(JSON.parse(file.response.msg).data.face.data)
        Object.assign(this.messageForm, JSON.parse(file.response.msg).data.face.data)
      }

      const isJPG = file.raw.type === 'image/jpeg'
      const isPNG = file.raw.type === 'image/png'
      const isLt10M = file.raw.size / 1024 / 1024 < 10

      if (file.raw) {
        if (isJPG || isPNG) {
          // const fileFormData = new FormData()
          // fileFormData.append('files', file.raw)
          this.imageUrlFront = URL.createObjectURL(file.raw)
          // console.log('---fileFormData---')
        } else {
          this.$message.error('上传图片只能是 JPG或PNG 格式!')
        }
      }

      if (!isLt10M) {
        this.$message.error('上传图片大小不能超过 10MB!')
      }
      // 返回图是jpg或png格式并且大小不超过2MB
      return (isJPG || isPNG) && isLt10M
    },

    changePictureUploadIDFront2(file, fileList) {
      if (this.modifyLoading) {
        this.$message({
          message: '请检查个人 信息是否正确',
          type: 'warning'
        });
        // 关闭定时器
        this.modifyLoading = false;
      } else {
        // 打开定时器
        this.modifyLoading = true;
      }

      if (file.response) {
        // console.log(JSON.parse(file.response.msg).data.back.data)
        Object.assign(this.messageForm, JSON.parse(file.response.msg).data.back.data)
        // console.log(this.messageForm)
      }

      this.newData2.name = this.newData.name
      this.newData2.idNumber = this.newData.idNumber
      // console.log(`---file---`, file)
      const isJPG = file.raw.type === 'image/jpeg'
      const isPNG = file.raw.type === 'image/png'
      const isLt10M = file.raw.size / 1024 / 1024 < 3

      if (file.raw) {
        if (isJPG || isPNG) {
          // const fileFormData = new FormData()
          // fileFormData.append('files', file.raw)
          this.imageUrlFront2 = URL.createObjectURL(file.raw)
          // console.log('---fileFormData---')
        } else {
          this.$message.error('上传图片只能是 JPG或PNG 格式!')
        }
      }

      if (!isLt10M) {
        this.$message.error('上传图片大小不能超过 10MB!')
      }
      // 返回图是jpg或png格式并且大小不超过2MB
      return (isJPG || isPNG) && isLt10M
    },

    refreshLX() {
      this.deleteFlag = false;
    },
    deleteItem() {
      this.deleteFlag = true;
    },


  }
}
</script>

<style lang="scss" scoped>
.background {
  width: 100%;
  height: 100%;
  /**宽高100%是为了图片铺满屏幕 **/
  z-index: -1;
  position: absolute;
}

.logo {
  width: 58px;
  height: 58px;
}

.container {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding-top: 20px;
  box-sizing: border-box;
}

.header {
  height: 40px;
  line-height: 40px;
  background-color: #303030;
  color: aliceblue;
  font-size: 14px;

  .header-top {
    display: flex;
    justify-content: space-between;
    background-color: #303030;
    color: aliceblue;
  }

  .header-top-right,
  .header-top-right>div {
    display: flex;
    align-items: center;
  }

  .header-top-right img {
    width: 16px;
    height: 16px;
    margin: 0 10px;
  }
}


//-------------------
.modify {
  width: 900px;
  margin: 0 auto;
  // height: 505px;
  box-sizing: border-box;
  padding: 30px 24px;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px 0px rgba(3, 27, 78, 0.06);
  border-radius: 20px;

  .modify-bu {
    position: relative;
    display: flex;

    img {
      width: 50%;
      height: 56px;
    }

    .modify-bu-zi {
      width: 100%;
      position: absolute;
      height: 56px;
      line-height: 56px;
      display: flex;
      justify-content: space-around;

      .modify-bu-zi-zou {
        display: flex;
        align-items: center;
        height: 56px;
        line-height: 56px;
        text-align: center;

        span {
          display: inline-block;
          width: 32px;
          height: 32px;
          line-height: 32px;
          margin-right: 8px;
          background: #FFFFFF;
          font-size: 16px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #667280;
          border-radius: 32px 32px 32px 32px;
          opacity: 1;
          border: 1px solid #EDEFF2;
        }
      }

      .modify-bu-zi-zou2 {
        display: flex;
        align-items: center;
        height: 56px;
        line-height: 56px;
        text-align: center;

        span {
          display: inline-block;
          width: 32px;
          height: 32px;
          line-height: 32px;
          margin-right: 8px;
          font-size: 16px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: #1371E7;
          background: #FFFFFF;
          border-radius: 32px 32px 32px 32px;
          opacity: 1;
          border: 1px solid #FFFFFF;
        }
      }
    }
  }

  .touimg {
    position: relative;
    width: 365px;
    height: 218px;
    //border: 1px dashed #333;
    line-height: 180px;
    text-align: center;
    //background-image: ;
    background-image: url("../../assets/home/img-正面照.png");
  }

  .touimg2 {
    position: relative;
    width: 365px;
    height: 218px;
    //border: 1px dashed #333;
    line-height: 218px;
    text-align: center;
    //background-image: ;
    background-image: url("../../assets/home/img-反面照.png");
  }

  .modify-content1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;

    .content-login-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 32px;

      h3 {
        font-size: 28px;
        margin: 0 48px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
      }

      img {
        width: 92px;
        height: 2px;
        background: linear-gradient(287deg, #D9D9D9 0%, rgba(217, 217, 217, 0) 100%);
      }
    }

    .pointOut {
      text-align: left;
      border: 1px solid #FECCA0;
      background-color: #FFF5EC;
      color: #333333;
      border-radius: 3px;
      padding: 15px;
      margin-bottom: 30px;

      .waring {
        // text-align: center;
        // padding: 4px 0 4px 10px;
        // margin-right: 10px;
        // background: #F2994A;
        // border-radius: 50%;
        // color: white;
        display: inline-block;
        width: 20px;
        line-height: 20px;
        padding-left: 6px;
        margin-right: 10px;
        box-sizing: border-box;
        text-align: center;
        border-radius: 50%;
        background-color: #F2994A;
        color: #fff;

      }
    }

    .zheng {
      position: absolute;
      top: 10%;
      left: 40%;
      // width: 70px;
      // height: 22px;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #1371E7;
    }

    .form-data {
      width: 700px;
      margin-top: 30px;
      text-align: center;

      .items {
        padding: 0 20px;

        .label {
          text-align: left;
          span{
            color: red;
          }
        }
      }

    }
  }

  .modify-content {
    display: flex;
    flex-direction: column;
    width: 340px;
    height: 250px;
    margin: 0 auto;
    margin-top: 60px;
    //background-color: #d0d0d0;

    .modify-input {

      span {
        display: block;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        padding-bottom: 8px;
        padding-top: 16px;

      }

      .modify-ma {
        display: flex;
      }
    }
  }

}

.message {
  font-size: 1.125rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.5rem;
  text-align: left;
  padding: 0.75rem 6.61157% 3rem 8.8154%;
}

.message::before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url('~@/assets/img/pre/icon-tip-1.png');
  background-size: 100% 100%;
  margin-right: 4px;
}

.flex_end_j {
  display: flex;
  justify-content: flex-end;
  text-align: center;
  padding-right: 4.40771%;
  height: 3.25rem;
  border-top: 0.0625rem solid #EDEFF2;

  .flex_cancel {
    width: 15.97796%;
    height: 2rem;
    background: #FFFFFF;
    border-radius: 10px;
    opacity: 1;
    border: 0.0625rem solid #E6E8EB;
    font-size: 0.8125rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #3E454D;
    line-height: 2rem;
    margin-right: 2.75482%;
    cursor: pointer;
  }

  .flex_confirm {
    display: flex;
    justify-content: center;
    width: 15.97796%;
    height: 2rem;
    background: #1371E7;
    box-shadow: 0px 0.25rem 0.375rem 0px rgba(19, 113, 231, 0.08);
    border-radius: 10px;
    font-size: 0.8125rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 2rem;
    cursor: pointer;
  }
}

//----------------

.bottom {
  width: 100%;
  height: 2.75rem;
  background: #181B20;
  font-size: 0.8125rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 1.75rem;

  .bottom-content {
    width: 61.458%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .bottom-right {
    display: flex;
    align-items: center;
  }

  .bottom-right::before {
    content: "";
    display: inline-block;
    width: 1.25rem;
    height: 1.25rem;
    background: url('~@/assets/home/bradge.png');
    background-size: 100% 100%;
    margin-right: 1.375rem;
  }
}

.footer {
  padding-top: 30px;
    padding-bottom: 20px;
    box-sizing: border-box;
    width: 100%;
    // height: 13rem;
    opacity: 1;
    background-image: url('~@/assets/img/pre/bg-footer.png');

  .footer-content {
    width: 61.458%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding: 3rem 0 1.5rem 0;
    align-items: center;

    .footer-top-line-1 {
      width: 25.254%;
      height: 0;
      opacity: 0.4;
      border: 0.125rem solid;
      border-image: linear-gradient(191deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 2 2;
    }

    .footer-top-line-2 {
      width: 25.254%;
      height: 0;
      opacity: 0.4;
      border: 0.125rem solid;
      border-image: linear-gradient(191deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 2 2;
      transform: rotate(180deg);
    }

    .footer-top-line-font {
      //margin: 0 1.1%;
      font-size: 1rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.47);
      line-height: 1.25rem;
    }
  }

  .footer-detail {
    width: 61.458%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    text-align: left;
    font-size: 1rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 1.25rem;
    align-items: center;

    img {
      width: 100%;
      //height: 60px;
    }

    ul {
      list-style-type: none;
      margin-top: 1.75rem;

      li {
        margin: 0.625rem 0;
      }
    }
  }
}</style>